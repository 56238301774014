import { Link } from "react-router-dom";
import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";

export default function Footer() {
  const [settings, setSettings] = useState([]);
  useEffect(() => {
    axios
      .get("https://store.edrobs.net/api/setting")
      .then((res) => setSettings(res.data.data));
  }, []);

  return (
    <div className="footer">
      <div className="footer-bg">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-2 col-sm-6 col-12">
              <div className="footer-links">
                <p>
                  <Link to={"/"}>الرئيسية</Link>
                </p>
                <p>
                  <Link to={"/"}>المميزات</Link>
                </p>
                <p>
                  <Link to={"/news"}>الأخبار</Link>
                </p>
                <p>
                  <Link to={"/contact"}>تواصل معنا</Link>
                </p>
                <p>
                  <Link to={"/supplier-product"}>كن مورداً</Link>
                </p>
                <p>
                  <Link to={"/sale-product"}>كن بائعا</Link>
                </p>
              </div>
            </div>

            <div className="col-lg-5 col-sm-6 col-12">
              <div className="footer__right">
                <div className="logo d-flex align-items-center justify-content-center">
                  <h1>EDROBS</h1>
                </div>
                <p>{settings.desc}</p>
                <div className="social">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="faceback">
                      <a
                        href={settings.facebook}
                        className=" text-decoration-none"
                      >
                        <span>
                          <i class="fa-brands fa-facebook"></i>
                        </span>
                      </a>
                    </div>
                    <div className="whats">
                      <a
                        href={settings.wattsapp}
                        className=" text-decoration-none"
                      >
                        <span>
                          <i class="fa-brands fa-whatsapp"></i>
                        </span>
                      </a>
                    </div>
                    <div className="insta">
                      <a
                        href={settings.instagram}
                        className=" text-decoration-none"
                      >
                        <span>
                          <i class="fa-brands fa-instagram"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-12">
              <div className="footer-left">
                <h3 className="footer-header">اشترك معنا</h3>
                <div className="row">
                  <div className="col-12">
                    <form>
                      <input
                        type="email"
                        placeholder="ادخل بريدك الالكتروني"
                      ></input>
                      <div className="footer-btn">
                        <button>اشترك معنا</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1 col-12"></div>
          </div>
        </div>
      </div>
      <div className="center-bottom text-center">
        <p>جميع الحقوق محفوظة لصالح EDROBS &copy; 2024 </p>
      </div>
    </div>
  );
}
