import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import './all.min.css'
import {  HashRouter } from "react-router-dom";
import 'hover.css/css/hover-min.css'
export default App;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
      <App />
  </HashRouter>
);
