import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import React, { useState, useEffect } from "react";
import axios from "axios";

export default function Home() {
  // const targetNumber = statics.map((num) => num.number);
  // const [count, setCount] = useState(statics.map((num) => num.number) - 100);
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (count < targetNumber) {
  //       setCount((prevCount) => prevCount + 1);
  //     } else {
  //       clearInterval(intervalId);
  //     }
  //   }, 1);

  //   return () => {
  //     clearInterval(intervalId); // Cleanup interval on component unmount
  //   };
  // }, [count, targetNumber]);

  const [banners, setBanners] = useState([]);
  useEffect(() => {
    axios
      .get("https://store.edrobs.net/api/home")
      .then((res) => setBanners(res.data.data.banners));
  }, []);

  const [steps, setSteps] = useState([]);
  useEffect(() => {
    axios.get("https://store.edrobs.net/api/home").then((res) => {
      setSteps(res.data.data.steps);
    });
  }, []);

  const [terms, setTerms] = useState([]);
  useEffect(() => {
    axios.get("https://store.edrobs.net/api/home").then((res) => {
      setTerms(res.data.data.terms);
    });
  }, []);

  const [feautures, setFeautures] = useState([]);
  useEffect(() => {
    axios.get("https://store.edrobs.net/api/home").then((res) => {
      setFeautures(res.data.data.feautures);
    });
  }, []);

  const [countries, setCountries] = useState([]);
  useEffect(() => {
    axios.get("https://store.edrobs.net/api/home").then((res) => {
      setCountries(res.data.data.countries);
    });
  }, []);

  const [statics, setStatics] = useState([]);
  useEffect(() => {
    axios.get("https://store.edrobs.net/api/home").then((res) => {
      setStatics(res.data.data.statics);
    });
  }, []);

  const [rates, setRates] = useState([]);
  useEffect(() => {
    axios.get("https://store.edrobs.net/api/home").then((res) => {
      setRates(res.data.data.rates);
    });
  }, []);

  const [asks, setAsks] = useState([]);
  useEffect(() => {
    axios.get("https://store.edrobs.net/api/home").then((res) => {
      setAsks(res.data.data.asks);
    });
  }, []);

  return (
    <section className="home">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination]}
        className="mySwiper swiper_header"
      >
        {banners.map((item) => (
          <SwiperSlide>
            <div className="header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="header-right">
                      <h1>{item.title}</h1>
                      <p>{item.desc}</p>
                      <div className="header-right__button">
                        <Link to={item.link} className="header-right__link">
                          اشترك الان
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="header-left">
                      <img
                        src={item.img}
                        className="header-img"
                        alt="header"
                        width={100}
                        height={100}
                      ></img>
                    </div>
                  </div>
                  <div className="col-lg-1"></div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="work">
        <div className="container-fluid">
          <div className="work_head" data-aos="fade-down">
            <h1>كيف تبدا العمل مع إي دروبس ؟</h1>
          </div>

          <Swiper
            slidesPerView={3}
            spaceBetween={20}
            loop={true}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              850: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper swiper__work"
          >
            {steps.map((step) => (
              <SwiperSlide>
                <div className="work__work1" data-aos="zoom-in">
                  <span className="work-img">
                    <img src={step.img} alt="work-img"></img>
                  </span>
                  <h3 className="work-plan">{step.title}</h3>
                  <p className="work-text">{step.desc}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="faq">
        <div className="row container-fluid">
          <div className="work_head" data-aos="fade-left">
            <h1>على ماذا يحتوي إي دروبس؟</h1>
          </div>
          <div className="col-md-6 col-12">
            <div class="accordion accordion-flush" id="accordionFlushExample">
              {terms.map((term) => (
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#accordion${term.id}`}
                      aria-expanded="false"
                      aria-controls={`accordion${term.id}`}
                      key={term.id}
                    >
                      <span>{term.name}</span>
                    </button>
                  </h2>
                  <div
                    id={`accordion${term.id}`}
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">{term.desc}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-6 col-12">
            <img
              src={require("./Assets/Image 2024-02-15 at 17.07.50_3be3a605.jpg")}
              style={{ width: "100%" }}
              className="faq-img"
              alt="faq"
            ></img>
          </div>
        </div>
      </div>

      <div className="services" id="services">
        <div className="container">
          <div className="work_head">
            <h1>لماذا تختار العمل مع منصة اي إي دروبس</h1>
          </div>

          <div className="row">
            {feautures.map((feature) => (
              <div className="col-lg-4 col-md-6 col-12">
                <div className="services__content">
                  <div className="content-head">
                    <h3>{feature.title}</h3>
                  </div>
                  <div className="content-body">{feature.desc}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="countries">
        <div className=" container-fluid">
          <div className="work_head">
            <h1>
              لدينا كل ما تحتاجه لبدء تجارتك الإلكترونية بالدفع عند الاستلام في
              العديد من الدول
            </h1>
          </div>
          <Swiper
            slidesPerView={4}
            spaceBetween={30}
            loop={true}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper swiper__countries py-2"
          >
            {countries.map((country) => (
              <SwiperSlide className="d-flex flex-column">
                <div className="contry-img text-center">
                  <img
                    src={country.img}
                    alt="country"
                    style={{ width: "100px", height: "100px" }}
                  ></img>
                </div>
                <div className="contry-head text-center">
                  <h3>{country.name}</h3>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div className="counter">
        <div className="container-fluid">
          <div className="container-bg">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                {statics.map((state) => (
                  <div className="col-md-4 col-12 text-center">
                    <div className="counter-icon">
                      <img src={state.img} alt="counter-img"></img>
                    </div>
                    <div className="counter-number">
                      <h3>{state.number}+</h3>
                    </div>
                    <div className="counter-name">
                      <h3>{state.title}</h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="options">
        <div className="container">
          <div className="work_head">
            <h1>آراء عملائنا</h1>
          </div>
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10">
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Navigation]}
                className="mySwiper swiper__option"
              >
                {rates.map((rate) => (
                  <SwiperSlide>
                    <div className="swiper-content">
                      <div className="option-img d-flex justify-content-center align-items-center">
                        <img
                          src={rate.img}
                          alt="flag1"
                          style={{ width: "100px" }}
                        ></img>
                      </div>
                      <div className="content">
                        <div className="content-name">
                          <p>{rate.user}</p>
                        </div>
                        <div className="content-country">
                          <p>{rate.country}</p>
                        </div>
                        <div className="content-text">{rate.msg}</div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="col-md-3 col-1"></div>
          </div>
        </div>
      </div>

      <div className="faq">
        <div className="row  container-fluid">
          <div className="work_head">
            <h1>الأسئلة الشائعة عن إي دروبس واجابتها</h1>
          </div>
          <div className="col-lg-6 col-12">
            <div class="accordion accordion-flush" id="accordionFlushExample">
              {asks.map((ask) => (
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#question${ask.id}`}
                      aria-expanded="false"
                      aria-controls={`question${ask.id}`}
                      key={ask.id}
                    >
                      <span>{ask.ask}</span>
                    </button>
                  </h2>
                  <div
                    id={`question${ask.id}`}
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">{ask.answer}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <img
              src={require("./Assets/Image 2024-02-15 at 17.07.48_683adb79.jpg")}
              style={{ width: "100%"}}
              className="faq-img"
              alt="faq"
            ></img>
          </div>
        </div>
      </div>
    </section>
  );
}
