import { Link } from "react-router-dom";
import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";

export default function News() {
  const [items, setItems] = useState([]);
  useEffect(() => {
    axios.get("https://store.edrobs.net/api/news").then((res) => {
      setItems(res.data.data);
    });
  }, []);

  return (
    <div className="news">
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <Link to={"/"}>الرئيسية</Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              الأخبار
            </li>
          </ol>
        </nav>
        {items.map((item) => (
          <div className="news__content" key={item.id}>
            <div className="row mb-5">
              <div className="col-md-3">
                <div className="content-img">
                  <img alt="img" src={item.img} style={{ width: "100%" }}></img>
                </div>
              </div>
              <div className="col-md-7">
                <div className="content-title">
                  <h3>{item.title}</h3>
                </div>
                <div className="content-desc">
                  <Link to={`/news-details/${item.id}`} className="text-decoration-none">
                    <p>
                      {item.desc.length > 250
                        ? `${item.desc.substring(0, 200)}....... قراءة المزيد`
                        : item.desc}
                    </p>
                  </Link>
                </div>

                <div className="content-time d-flex">
                  <p>
                    <span>
                      <i class="fa-regular fa-calendar-days ms-2"></i>
                    </span>
                    <span>{item.created_at}</span>
                  </p>
                  <p className="mx-4">
                    <span>
                      <i class="fa-solid fa-user ms-2"></i>
                    </span>
                    <span>{item.admin}</span>
                  </p>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
