export default function Contact() {
  return (
    <div className="contact">
      <div className="container">
        <div className="contact-bg">
          <div className="row contact-flex">
            <div className="col-md-6 col-12">
              <div className="container py-3 overflow-hidden">
                <div className="contact__head">
                  <h4>اتصل بنا</h4>
                  <p>كيف يمكن أن نساعد؟</p>
                </div>
                <div className="contact-form">
                  <form>
                    <div class="row">
                      <div
                        className="col-12"
                        // data-aos="fade-up"
                        // data-aos-duration="3000"
                      >
                        <input type="text" placeholder="اسمك"></input>
                      </div>
                      <div
                        className="col-12"
                        data-aos="fade-up"
                        data-aos-duration="3000"
                      >
                        <input
                          type="email"
                          placeholder="بريدك الإلكتروني"
                          required
                        ></input>
                      </div>
                      <div
                        className="col-12"
                        data-aos="fade-up"
                        data-aos-duration="3000"
                      >
                        <input type="text" placeholder="الموضوع"></input>
                      </div>
                      <div
                        className="col-12"
                        data-aos="fade-up"
                        data-aos-duration="3000"
                      >
                        <textarea
                          cols="50"
                          rows="10"
                          placeholder="رسالتك"
                        ></textarea>
                      </div>
                      <div
                        className="contact-btn"
                        data-aos="fade-up"
                        data-aos-duration="3000"
                      >
                        <button>إرسال</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 contact-img p-0">
              <img
                src={require("./Assets/contact/2642881.jpeg")}
                alt="contact-img"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
