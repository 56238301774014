export default function ChoosePage() {
  return (
    <div className="choose">
      <div className="container">
        <div className="head">
          <h1 className="logo m-0">
            <span style={{ color: "#fe914a" }}>EDR</span>O
            <span style={{ color: "#fe914a" }}>BS</span>
          </h1>
        </div>
        <div className="body">
          <h2 className="text-with-shadow">مرحباً بك في إي دروبس</h2>
        </div>
        <div className="links">
          <button className="hvr-pulse-grow">
            <a href="https://store.edrobs.net/user/login">
              تسجيل دخول المورد
            </a>
          </button>

          <button className="hvr-pulse-grow">
            <a href="https://store.edrobs.net/saller/login">
              تسجيل دخول البائع
            </a>
          </button>
        </div>
        <div className="img-choose">
          <div className="row">
            <div className="col-lg-4 col-md-3 col-1"></div>
            <div className="col-lg-4 col-md-6 col-10">
              <img
                src={require("./Assets/Image 2024-02-15 at 17.07.46_4cc48f94.jpg")}
                alt="img"
                style={{width:'100%'}}
              ></img>
            </div>
            <div className="col-lg-4 col-md-3 col-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
