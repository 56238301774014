/* eslint-disable no-undef */
import { Link, NavLink } from "react-router-dom";

export default function Navbar() {
  return (
    <div className="navbar p-0">
      <nav class="navbar navbar-expand-lg w-100">
        <div class="container-fluid">
          <h1 className="logo m-0">
            <span style={{ color: "#fe914a" }}>EDR</span>O
            <span style={{ color: "#fe914a" }}>BS</span>
          </h1>

          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            class="collapse navbar-collapse d-lg-flex justify-content-lg-between align-items-lg-center "
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav mb-2 mb-lg-0">
              <li class="nav-item">
                <NavLink
                  exact
                  activeClassName="active-link"
                  className="nav-link"
                  to={"/"}
                  
                >
                  الرئيسية
                </NavLink>
              </li>
              <li class="nav-item">
                    <a href="#/" className="nav-link">
                    المميزات
                    </a>
              </li>
              <li class="nav-item">
                <NavLink
                  className="nav-link"
                  to={"/news"}
                  activeClassName="active-link"
                >
                  الأخبار
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink
                  className="nav-link"
                  to={"/contact"}
                  activeClassName="active-link"
                >
                  تواصل معنا
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink
                  className="nav-link"
                  to={"/supplier-product"}
                  activeClassName="active-link"
                >
                  كن مورداً
                </NavLink>
              </li>
              <li class="nav-item">
                <NavLink
                  className="nav-link"
                  to={"/sale-product"}
                  activeClassName="active-link"
                >
                  كن بائعا
                </NavLink>
              </li>
            </ul>
            <div className="account d-lg-flex">
              <li class="nav-item hvr-forward" style={{ listStyle: "none" }}>
                <Link className="nav-link" to='/ChooseLink'>
                  <span>تسجيل الدخول</span>
                  <span className="p-1 icon-login">
                    <i class="fa-solid fa-right-to-bracket "></i>
                  </span>
                </Link>
              </li>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
