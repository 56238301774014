import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

export default function ItemDetails() {
  const { id } = useParams();

  const [details, setDetails] = useState('');

  useEffect(() => {
    axios.get(`https://store.edrobs.net/api/news-details/${id}`).then((res) => {
      setDetails(res.data.data)
      
    });
    
  },[])

  return (
    <div key={id}>
        <div className="new-details my-3">
            <div className="container">
                <div className="details-img">
                    <img src={details.img} alt="details-img"></img>
                </div>
                <div className="details-header">
                    <h3 className="mt-2">
                        {details.title}
                    </h3>
                    <p>{details.desc}</p>
                </div>
                <div className="content-time d-flex">
                  <p>
                    <span>
                      <i class="fa-regular fa-calendar-days ms-2"></i>
                    </span>
                    <span>{details.created_at}</span>
                  </p>
                  <p className="mx-4">
                    <span>
                      <i class="fa-solid fa-user ms-2"></i>
                    </span>
                    <span>{details.admin}</span>
                  </p>
                </div>
            </div>
        </div>
    </div>
  );
}
