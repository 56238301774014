import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

export default function Sale() {
  const [asks, setAsks] = useState([]);
  useEffect(() => {
    axios.get("https://store.edrobs.net/api/home").then((res) => {
      setAsks(res.data.data.asks);
    });
  }, []);

  const [statics, setStatics] = useState([]);
  useEffect(() => {
    axios.get("https://store.edrobs.net/api/home").then((res) => {
      setStatics(res.data.data.statics);
    });
  }, []);

  const [feautures, setFeautures] = useState([]);
  useEffect(() => {
    axios.get("https://store.edrobs.net/api/home").then((res) => {
      setFeautures(res.data.data.feautures);
    });
  }, []);

  return (
    <div className="sale">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="sale-content">
              <h1>كن بائعًا</h1>
              <h3>انضم الى فريق البائعين المحترفين في منصة اي إي دروبس</h3>
              <p>
                حقق دخل شهري عبر الانضمام الى فريق اي إي دروبس التسويقي ! سجل
                الان وقم بتحقيق احلامك
              </p>
              <div className="sale-button">
                <Link to={"https://store.edrobs.net/saller/show-register"} className="sale-link">
                  <span>قدّم طلبك لتصبح بائعا!</span>
                  <span className="sale-icon">
                    <i class="fa-solid fa-arrow-right"></i>
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="sale-img">
              <img
                src={require("./Assets/Image 2024-02-15 at 17.07.46_4cc48f94.jpg")}
                alt="sale"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="services my-5" id="services">
        <div className="container">
          <div className="work_head">
            <h1>لماذا تختار العمل مع منصة اي إي دروبس</h1>
          </div>

          <div className="row">
            {feautures.map((feature) => (
              <div className="col-lg-4 col-md-6 col-12">
                <div className="services__content">
                  <div className="content-head">
                    <h3>{feature.title}</h3>
                  </div>
                  <div className="content-body">{feature.desc}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="counter">
        <div className="container-fluid">
          <div className="container-bg">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                {statics.map((state) => (
                  <div className="col-md-4 col-12 text-center">
                    <div className="counter-icon">
                      <img src={state.img} alt="counter-img"></img>
                    </div>
                    <div className="counter-number">
                      <h3>{state.number}+</h3>
                    </div>
                    <div className="counter-name">
                      <h3>{state.title}</h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="faq">
        <div className="row  container-fluid">
          <div className="work_head">
            <h1>الأسئلة الشائعة عن إي دروبس واجابتها</h1>
          </div>
          <div className="col-lg-6 col-12">
            <div class="accordion accordion-flush" id="accordionFlushExample">
              {asks.map((item) => (
                <div class="accordion-item mt-0">
                  <h2 class="accordion-header" id="flush-headingOneOne">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#questions${item.id}`}
                      aria-expanded="false"
                      aria-controls={`questions${item.id}`}
                      key={item.id}
                    >
                      <span>{item.ask}</span>
                    </button>
                  </h2>
                  <div
                    id={`questions${item.id}`}
                    class="accordion-collapse collapse"
                    aria-labelledby="flush-headingOneOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">{item.answer}</div>
                  </div>
                </div>
              ))}
              {/* <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwoTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#questionTwo"
                    aria-expanded="false"
                    aria-controls="questionTwo"
                  >
                    <span>هل إي دروبس مناسب لي؟</span>
                  </button>
                </h2>
                <div
                  id="questionTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwoTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    إذا كنت صاحب مصنع أو مورد بالجملة، فقد يكون توسيع قناة
                    التوزيع الخاصة بك لتشمل البائعين عبر الإنترنت مفيدة جدا لك !
                    تعطي إي دروبس الأولوية للموردين الموجودين في آسيا وأفريقيا،
                    ولكننا منفتحون على جميع الموردين على مستوى العالم .
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingThreeThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#questionThree"
                    aria-expanded="false"
                    aria-controls="questionThree"
                  >
                    <span>كيف تتم عملية الدفع في إي دروبس؟</span>
                  </button>
                </h2>
                <div
                  id="questionThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingThreeThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    يتم الدفع للموردين في إي دروبس على أساس أسبوعي. بالنسبة لمعظم
                    الموردين، تتم معالجة هذه العوائد يوم الخميس ويتم استلامها في
                    حسابك المصرفي يوم الجمعة.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFourFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#questionFour"
                    aria-expanded="false"
                    aria-controls="questionFour"
                  >
                    <span>كيف يتم التعامل مع المرتجعات والمبالغ المستردة؟</span>
                  </button>
                </h2>
                <div
                  id="questionFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingFourFour"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    كل مورد على إي دروبس قادر على تحديد سياسة الإرجاع والإستبدال
                    الخاصة به. يعرض العديد من الموردين عمليات الإرجاع والإستبدال
                    لمدة 14 يوم كخاصية لجذب المسوقين ، في حين قد لا يكون لدى بعض
                    الموردين سياسة إرجاع أو استبدال . في الحالات القصوى، تحتفظ
                    إي دروبس بالحق في إلغاء سياسة الإرجاع الخاصة بالمورد.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFiveFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#questionFive"
                    aria-expanded="false"
                    aria-controls="questionFive"
                  >
                    <span>لدي موزعين خاصين بي، كيف يمكنني ضمهم إلى إي دروبس؟</span>
                  </button>
                </h2>
                <div
                  id="questionFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingFiveFive"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    قم بإحالة تجار التجزئة لديك إلى إي دروبس لمزامنة تحميلات
                    المنتجات الجديدة والمخزون والطلبات مع البائعين بسلاسة. سوف
                    تكسب أيضًا عمولة ثابتو على كل طلب مسلم لكل بائع تحضره إلى
                    إي دروبس.
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <img
              src={require("./Assets/Image 2024-02-15 at 17.07.48_683adb79.jpg")}
              className="faq-img1"
              alt="faq"
              style={{ width: "80%" }}
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}
