import Navbar from "./component/Navbar";
import "./navbar.css";
import Home from "./Home";
import "./Home.css";
import Footer from "./component/Footer";
import "./footer.css";
import { Route, Routes } from "react-router-dom";
import News from "./News";
import "./news.css";
import Supplier from "./Supplier";
import "./Supplier.css";
import Sale from "./Sale";
import "./Sale.css";
import Contact from "./Contact";
import "./contact.css";
import ItemDetails from "./ItemDetails";
import ChoosePage from "./ChoosePage";
import "./choose.css";
export default function App() {
  return (
    <>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/news" element={<News />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/supplier-product" element={<Supplier />}></Route>
          <Route path="/sale-product" element={<Sale />}></Route>
          <Route path="/news-details/:id" element={<ItemDetails />}></Route>
          <Route path="/sale-product" element={<Sale />}></Route>
          <Route path="/ChooseLink" element={<ChoosePage />}></Route>
        </Routes>
        <Footer />
      </div>
    </>
  );
}
